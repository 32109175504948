<template>
  <v-app dark>
    <v-content>
      <v-container fill-height>
        <v-layout align-center justify-center row fill-height>
          <v-flex md5 lg4 xl3 justify-center>
            <a href="/">
              <img :src="logoPath" alt="Drogon" id="logo" />
            </a>

            <v-alert :value="error" type="error" class="mb-4"><div v-html="errorMsg"></div></v-alert>

            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              fill-width
              action="/sessions"
              method="post"
              v-on:submit.prevent="submit"
            >
              <v-text-field
                v-model="login"
                :rules="loginRules"
                solo
                type="text"
                label="Username or E-Mail"
                required
                autocomplete="username"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                @click:append="showPassword = !showPassword"
                solo
                required
                autocomplete="password"
              ></v-text-field>

              <vue-hcaptcha
                id="hcaptcha-container"
                :sitekey="siteKey"
                theme="dark"
                size="invisible"
                @verify="captchaVerify"
                ref="invisibleHcaptcha"
              ></vue-hcaptcha>

              <v-btn
                block
                type="submit"
                color="success"
                :disabled="!valid || isSubmitting || disableSubmit"
                @click.prevent="submit"
                :loading="isSubmitting"
                class="mb-5"
              >
                Sign In
              </v-btn>
            </v-form>

            <v-btn
              block
              href="//rapidvps.is/amember/login?sendpass"
              rel="nofollow"
              class="mb-5"
              v-if="purchaseLinkText !== 'Renew'"
            >
              Forgot Password?
            </v-btn>

            <v-divider class="mb-5"></v-divider>

            <v-btn
              color="action"
              light
              block
              large
              href="//rapidvps.is/amember/signup"
              rel="nofollow"
            >
              {{purchaseLinkText}} <v-icon style="margin-left:10px;">double_arrow</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import LogoImg from "./src/assets/images/logo.svg"
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  data: () => ({
    logoPath: LogoImg,
    hcaptchaSiteKeyProduction: '8f49c1ca-6cfb-4a13-b38a-60e994cf511f',
    hcaptchaSiteKeyDev: '10000000-ffff-ffff-ffff-000000000001',
    isSubmitting: false,
    disableSubmit: false,
    valid: true,
    login: '',
    password: '',
    showPassword: false,
    hcaptchaResponse: false,
    purchaseLinkText: 'Sign Up',
    loginRules: [
      v => !!v || 'Username or E-Mail is required'
    ],
    passwordRules: [
      v => !!v || 'Password is required',
      v => (v && v.length >= 6) || 'Password must be at least 6 characters'
    ],
    error: false,
    errorMsg: "Something went wrong."
  }),
  components: {
    VueHcaptcha
  },
  mounted() {
    // this.$ga.page('/signin')
  },
  computed: {
    allValid() {
      return (this.valid && !!this.hcaptchaResponse)
    },
    siteKey() {
      if(process.env.NODE_ENV === 'development') {
        return this.hcaptchaSiteKeyDev;
      } else {
        return this.hcaptchaSiteKeyProduction;
      }
    }
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        this.snackbar = true
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    captchaVerify(response) {
      this.hcaptchaResponse = response;

      if (this.allValid) {
        this.postRequest();
      }
    },
    resetCaptcha() {
      this.$refs.invisibleHcaptcha.reset();
      this.hcaptchaResponse = false;
    },
    postRequest() {
      this.isSubmitting = true;
      this.$http.post(`/sessions`,
        {
          'h-captcha-response': this.hcaptchaResponse,
          session: {
            login: this.login,
            password: this.password
          }
        }

      // Server responding normally
      ).then(response => {
        // 1 - Sign in successfully
        if (response.data.message == 'signin') {
          this.$http.redirectTo("/app");

        // 2 - No suscribtion - Renew
        } else if (response.data.message.toLowerCase().includes('renew')) {
          this.error         = true;
          this.isSubmitting  = false;
          this.errorMsg      = response.data.message;
          this.resetCaptcha();
          this.purchaseLinkText = "Renew";

        // 3 - Server Validation error
        } else {
          this.error         = true;
          this.isSubmitting  = false;
          this.errorMsg      = response.data.message;
          this.resetCaptcha();
        }

      // Server level errors - 40x, 50x
      }).catch((error, response) => {
        console.log(error);
        this.error        = true;
        this.isSubmitting = false;

        if (error.response.status == 422) {
          this.disableSubmit = true;
          this.errorMsg = "Please reload the page and try again.";
        } else if (error.response.status == 403) {
          this.disableSubmit = true;
          this.errorMsg = "Please disable any ad blocker blocking the CAPTCHA and reload the page.";
        } else {
          this.errorMsg = error.message;
        }
      })
    },
    submit() {
      if (!this.$refs.form.validate()) {
        this.isSubmitting = false;
      } else if (!this.hcaptchaResponse && this.isSubmitting == false) {
        this.$refs.invisibleHcaptcha.execute();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.theme--dark.application {
  background: black;
}
#logo {
  display: block;
  width: 150px;
  height: 150px;
  margin: 10px auto 40px;

  @media (min-width: 600px) { // Tablet-Desktop
    width: 200px;
    height: 200px;
    margin: 0 auto 45px;
  }
}
#hcaptcha-container {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 30px;
}
</style>
