/* eslint no-console:0 */

import Vue from 'vue'
import '../plugins'
import SigninApp from '../signin-app.vue'

Vue.config.productionTip = false

// Google Analytics
// import VueAnalytics from 'vue-analytics'
// Vue.use(VueAnalytics, {
//   id: 'UA-141756163-1',
//   debug: { sendHitTask: process.env.NODE_ENV === 'production' }
// })

// Load Vue
document.addEventListener('DOMContentLoaded', () => {
  const SigninAppTag  = "#signin-app"

  // Signed in
  if (document.querySelector(SigninAppTag)) {
    new Vue({
      render: h => h(SigninApp),
    }).$mount(SigninAppTag)
  }
})
